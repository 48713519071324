import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate,
} from 'react-router-dom';

const Homepage = lazy(async () => await import('./pages/homepage'));
const TermsOfService = lazy(async () => await import('./pages/terms-of-service'));
const PrivacyPolicy = lazy(async () => await import('./pages/privacy-policy'));
const Tokuteisho = lazy(async () => await import('./pages/tokuteisho'));
const RefundPolicy = lazy(async () => await import('./pages/refund-policy'));
const ShippingPolicy = lazy(async () => await import('./pages/shipping-policy'));
const Contact = lazy(async () => await import('./pages/contact'));

const RegionalWrapper = ({ children }: { children: React.ReactNode }) => {
  const supportedRegions = ['us', 'kr', 'jp', 'vn'];
  const fallbackRegion = 'us';

  // Extract the region from the URL path
  const region = window.location.pathname.split('/')[1];

  // Redirect to fallback region if the region is not supported
  if (!supportedRegions.includes(region)) {
    return <Navigate to={`/${fallbackRegion}`} replace />;
  }

  return <>{children}</>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Homepage */}
      <Route
        path="/:region"
        element={
          <RegionalWrapper>
            <Homepage />
          </RegionalWrapper>
        }
      />

      {/* Legal Pages */}
      <Route
        path="/:region/terms-of-service"
        element={
          <RegionalWrapper>
            <TermsOfService />
          </RegionalWrapper>
        }
      />
      <Route
        path="/:region/privacy-policy"
        element={
          <RegionalWrapper>
            <PrivacyPolicy />
          </RegionalWrapper>
        }
      />
      <Route
        path="/:region/tokuteisho"
        element={
          <RegionalWrapper>
            <Tokuteisho />
          </RegionalWrapper>
        }
      />
      <Route
        path="/:region/refund-policy"
        element={
          <RegionalWrapper>
            <RefundPolicy />
          </RegionalWrapper>
        }
      />
      <Route
        path="/:region/shipping-policy"
        element={
          <RegionalWrapper>
            <ShippingPolicy />
          </RegionalWrapper>
        }
      />

      {/* Contact Page */}
      <Route
        path="/:region/contact"
        element={
          <RegionalWrapper>
            <Contact />
          </RegionalWrapper>
        }
      />

      {/* Fallback to Homepage */}
      <Route
        path="*"
        element={
          <RegionalWrapper>
            <Homepage />
          </RegionalWrapper>
        }
      />
    </>
  )
);

const Loading = () => (
  <div className="bg-grey-5 text-grey-90 flex h-screen w-full items-center justify-center">
    <div
      className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
      role="status"
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
    </div>
  </div>
);

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
