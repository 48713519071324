import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// Map regions to language codes
const regionToLanguageMap: Record<string, string> = {
  us: 'en',
  kr: 'ko',
  jp: 'ja',
  vn: 'vi',
};

// Detect language from URL
const detectLanguageFromRegion = () => {
  const region = window.location.pathname.split('/')[1];
  return regionToLanguageMap[region] || 'en'; // Default to English if no region is found
};

export const supportedLanguages = [
  { locale: 'en', name: 'English' },
  { locale: 'ko', name: '한국어' },
  { locale: 'ja', name: '日本語' },
  { locale: 'vi', name: 'Tiếng Việt' },
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['common'], // Namespace used in translations
    defaultNS: 'common',
    backend: {
      loadPath: '/translations/{{lng}}/{{ns}}.json', // Translation files path
    },
    supportedLngs: supportedLanguages.map((lang) => lang.locale),
    fallbackLng: 'en', // Fallback to English
    lng: detectLanguageFromRegion(), // Dynamically set language from region
    interpolation: {
      escapeValue: false, // React already handles escaping
    },
    detection: {
      order: ['path', 'navigator'], // Check URL path for language first
      caches: [], // No language cache
    },
  });

export default i18n;
